<template>
  <div>
    <!-- 主体 -->
    <LiefengContent>
      <template v-slot:title>{{ "平台角色管理" }}</template>
      <!-- 头部搜索层 -->
      <template v-slot:toolsbarRight>
          <Input v-model.trim="searchForm.keyword" placeholder="请输入角色名称/编码" style="width: 160px;margin-right:10px"></Input>
          <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
          <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
          <Button
              icon="ios-add"
              type="primary"
              @click="addForm"
          >新增
          </Button>
      </template>
      <!-- 分页层 -->
      <template v-slot:contentArea>
        <!-- 分页table -->
        <LiefengTable
            :talbeColumns="pageForm.talbeColumns"
            :tableData="pageForm.tableData"
            :loading="controlForm.loading"
            :fixTable="true"
            :curPage="pageForm.page"
            :total="pageForm.total"
            :pagesizeOpts="[20, 30, 50, 100]"
            :page-size="pageForm.pageSize"
            @hadlePageSize="changeTableSize"
        ></LiefengTable>
        <FormAdd ref="FormAdd" @resetBtn="resetBtn"></FormAdd>
        <FormEdit ref="FormEdit" @resetBtn="resetBtn"></FormEdit>

        <LiefengModal
          title="授权员工"
          :value="menuStatus"
          @input="changeMenuStatus"
          :fullscreen="false"
          width="500px"
          height="600px"
          >
          <template v-slot:contentarea>
            <!-- <Tree 
              :data="menuTree" 
              show-checkbox
            >
            </Tree> -->
            <Checkbox v-model="e.status" v-for="(e,i) in staffList" :key="i">{{e.userName}}</Checkbox>
          </template>
          <template v-slot:toolsbar>
             <Button type="primary" style="margin-right:10px" @click="saveUser">保存</Button>
             <Button type="info" @click="changeMenuStatus(false)">取消</Button>
          </template>
        </LiefengModal>
      </template>
      <!-- 新增modal -->
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/add'
import FormEdit from './childrens/edit'

export default {
  components: {LiefengContent, LiefengTable, LiefengModal, FormAdd, FormEdit},
  data() {
    return {
      //分页参数
      pageForm: {
        talbeColumns: [
          {
            title: "角色编码",
            key: "roleCode",
            minWidth: 200,
            align: "center"
          },
          {
            title: "角色名称",
            key: "roleName",
            minWidth: 200,
            align: "center"
          },
          
          {
            title: "角色类型",
            minWidth: 200,
            align: "center",
            render(h, params){
              if (params.row.roleType == 1) {
                return h("div", {}, "全局角色")
              }
              if (params.row.roleType == 2) {
                return h("div", {}, "租户/平台角色")
              }
              if (params.row.roleType == 3) {
                return h("div", {}, "组织机构内角色")
              }
            }
          },
          // {
          //   title: "状态",
          //   minWidth: 200,
          //   align: "center",
          //   render(h, params){
          //     if (params.row.status == 1) {
          //       return h("div", {}, "启用")
          //     }
          //     if (params.row.status == 2) {
          //       return h("div", {}, "禁用")
          //     }
          //   }
          // },
          {
            title: "操作",
            align: "center",
            fixed: "right",
            width: 300,
            render: (h, params) => {
              return h(
                  "div",
                  {
                    style: {
                      textAlign: "center"
                    }
                  },
                  [
                    h(
                        "Dropdown",
                        {
                          props: {
                            transfer: true
                          }
                        },
                        [
                          h(
                              "Button",
                              {
                                props: {
                                  type: "success",
                                  size: "small",
                                  icon: "ios-arrow-down"
                                }
                              },
                              "操作"
                          ),
                          h(
                              "DropdownMenu",
                              {
                                slot: "list"
                              },
                              [
                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.getDetail(params.row.roleId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "修改"
                                ),

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.openMenuTree(params.row.roleId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "授权员工"
                                ),
                                // h(
                                //     "DropdownItem",
                                //     {
                                //       nativeOn: {
                                //         click: () => {
                                //         }
                                //       },
                                //       style: {
                                //         textAlign: "center"
                                //       }
                                //     },
                                //     "数据授权"
                                // ),
                                

                                h(
                                    "DropdownItem",
                                    {
                                      nativeOn: {
                                        click: () => {
                                          this.deleteRow(params.row.roleId)
                                        }
                                      },
                                      style: {
                                        textAlign: "center"
                                      }
                                    },
                                    "删除"
                                ),
                              ]
                          )
                        ]
                    )
                  ]
              );
            }
          },

        ],
        tableData: [],
        loading: false,
        page: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0
      },
      //控制的一些属性
      controlForm:{
        loading:false,
      },
      //查询参数
      searchForm: {
        keyword: '',
      },
      
      // 权限设置
      menuStatus:false,
      menuTree:[],
      tenantSystemModuleList:[],
      tenantSystemId:'',

      staffList:[],
      roleUser:[],
      roleId:''
    }
  },
  //create函数,刚加载页面时调用
  async created() {
    await this.selectPage();
  },
  methods: {
    /*----- 分页事件部分 -------*/

    //分页事件改变
    changeTableSize(val) {
      this.pageForm.page = val.page
      this.pageForm.pageSize = val.pageSize
      this.selectPage()
    },
    //查询按钮
    searchBtn() {
      this.pageForm.page = 1
      this.selectPage()
    },
    //重置按钮
    resetBtn() {
      this.searchForm.keyword = ''
      this.pageForm.page = 1
      this.selectPage()
    },


    /*----- modal部分 -------*/
    //新增按钮
    addForm() {
      this.$refs.FormAdd.controlForm.formFlag = true;
    },
    // 方法部分

    /*----- 接口部分 -------*/

    async selectPage() {
      this.controlForm.loading = true
      await this.$get('/auth/api/auth/pc/role/selectRoleByPage', {
        page: this.pageForm.page,
        pageSize: this.pageForm.pageSize,
        keyword: this.searchForm.keyword,
        oemCode:parent.vue.oemInfo.oemCode,
        // orgCode:parent.vue.loginInfo.userinfo.orgCode,
        // appCode:'suiyue'
      }).then(res => {
        this.controlForm.loading = false
        if (res.code == 200) {
          this.pageForm.tableData = res.dataList
          this.pageForm.total = res.maxCount
          this.pageForm.currentPage = res.currentPage
        } else {
          this.$Message.error({
            content: '获取列表失败',
            background: true
          })
          return
        }
      })
    },
    // 获取详情接口
    getDetail(roleId){
      this.$refs.FormEdit.getDetail(roleId)
      this.$refs.FormEdit.controlForm.formFlag = true
    },
    async deleteRow(roleId) {
      this.$Modal.confirm({
        title: "删除确定",
        content: "您正在对角色进行删除操作、并且当前操作不可逆转，是否继续？",
        onOk: () => {
          this.$post('/auth/api/auth/pc/role/delete',{
            roleId
          }).then(res=>{
            if(res.code == 200){
              this.$Message.success({
                content:'删除成功',
                background:true
              })
              this.resetBtn();
            }else{
              this.$Message.error({
                content:'删除失败',
                background:true
              })
              return
            }
          })
        },
      });
    },
    
    // 打开授权
    openMenuTree(roleId){
      this.menuStatus = true
      this.getRoleUser(roleId)
      this.roleId = roleId
      
    },

    //员工授权
    getStaffList(){
      this.$get('/auth/api/auth/pc/staff/selectByNoPage',{
        oemCode:parent.vue.oemInfo.oemCode,
      }).then( res => {
        if(res.code == 200){
          this.staffList = res.dataList.map( item => {
            if(this.roleUser.includes(item.staffId)){
              return {
                userName:item.userName,
                status:true,
                staffId:item.staffId,
                custGlobalId:item.custGlobalId
              }
            }else{
              return {
                userName:item.userName,
                status:false,
                staffId:item.staffId,
                custGlobalId:item.custGlobalId
              }
            }
              
          })
        }else{
          this.$Message.error({
            content:'获取员工失败',
            background:true
          })
        }
      })
    },
    getRoleUser(roleId){
      this.$get('/auth/api/auth/pc/role/getRoleUser',{
        roleId,
      }).then( res => {
        if(res.code == 200){
          // if(res.dataList.length != 0)
          res.dataList.map(item => {
            this.roleUser.push(item.staffId)
          })
          this.getStaffList()
        }else{
          this.$Message.error({
            content:'获取授权员工失败',
            background:true
          })
        }
      })
    },
    // 保存员工授权
    saveUser(){
      console.log(this.staffList)
      let custGlobalIds = []
      let staffIds = []
      this.staffList.map( item => {
        if(item.status){
          custGlobalIds.push(item.custGlobalId)
          staffIds.push(item.staffId)
        }
      })
      this.$post('/auth/api/auth/pc/role/saveRoleUser',{
        custGlobalIds:custGlobalIds.join(','),
        staffIds:staffIds.join(','),
        roleId:this.roleId,
      }).then( res => {
        if(res.code == 200){
          this.$Message.success({
            content:'授权成功',
            background:true
          })
          this.menuStatus = false
        }else{
          this.$Message.error({
            content:'授权失败',
            background:true
          })
        }
      })
    },

    // 获取菜单
    getMenuTree(){
      this.$get('/auth/api/auth/pc/systemModule/selectAllMenuTree',{
        orgCode:parent.vue.loginInfo.userinfo.orgCode,

      }).then(res => {
        if(res.code == 200){
          this.menuTree = res.dataList
          this.handleMenuTree(this.menuTree)
        }
      })
    },
    handleMenuTree(list){
      list.forEach( (e,i) => {
        list[i] = {
          title : e.name,
          expand : true,
          children: e.child,
          value:e.sysModuleId,
        }
        if(this.tenantSystemModuleList.includes(e.sysModuleId)){
          list[i].checked = true
        }else {
          list[i].checked = false
        }
        if (list[i].children) {
          this.handleMenuTree(list[i].children);
        } else {
          delete(list[i]['expand'])
          delete(list[i]['children'])
        }
      } )
    },

  }
}
</script>
<style lang="less" scoped>
/deep/ #toolsbarRight {
  width: 1000%;

  .search {
    width: 100%;
    text-align: right;

    .ivu-form-item {
      margin-bottom: 0;
    }
  }
}

// /deep/.ivu-modal-close {
//   display: none;
// }
</style>